import React from 'react';
import ScrollToTop from '../components/ScrollToTop';
import Header from '../components/Header';
import Rodape from '../components/Rodape';
import VpnIpParte1 from '../components/vpnIp/VpnIpParte1';
import VpnIpParte2 from '../components/vpnIp/VpnIpParte2';
import VpnIpParte3 from '../components/vpnIp/VpnIpParte3';
import VpnIpParte4 from '../components/vpnIp/VpnIpParte4';
import VpnIpParte5 from '../components/vpnIp/VpnIpParte5';
import HamburguerMenu from '../components/HamburguerMenu';

function VpnIp() {
  return <>
    <HamburguerMenu />
    <ScrollToTop />
    <Header />
    <VpnIpParte1 />
    <VpnIpParte2 />
    <VpnIpParte3 />
    <VpnIpParte4 />
    <VpnIpParte5 />
    <Rodape />
  </>;
}

export default VpnIp;