import React from 'react';
import BotaoContato from '../../components/BotaoContato';
import styles from '../NossasSolucoes.module.css';
import { columnClasses } from './../../sharedProperties';

function VpnIpParte2() {
    return (
        <div className="container" style={{ margin: "0px", maxWidth: "100%" }} >
            <div className="row" >
                <div className={`${columnClasses.colNormal} ${styles.nossasSolucoes} `}
                    style={{ background: `url("/fundoVpnIp/vpnIp_fundo_parte2_esquerda.png")` }}>
                    <div className={`d-flex flex-column justify-content-center text_align_right ${styles.nossasSolucoes} ${styles.sobreNosTexto}`}>
                        <h1>interligação de
                        matriz e filiais
                        via Internet</h1>
                        <p>
                            Nossa solução oferece uma <span>rede virtual privada, robusta e escalável</span>, garantindo proteção avançada para suas comunicações empresariais.Sua empresa terá <span>acesso seguro</span> a recursos internos, independentemente da localização física. A <span>alta disponibilidade e confiabilidade</span> da nossa infraestrutura garantem que suas operações não sejam interrompidas, mesmo em situações adversas
                        </p>
                        <BotaoContato text="PROTEGER SUA EMPRESA AGORA" />
                    </div>
                </div>
                <div className={`${columnClasses.colNormal} ${styles.nossasSolucoes} `}>
                    <img src="/fundoVpnIp/vpnIp_fundo_parte2_direita.png" alt="" className={`${styles.imagem}`} />
                </div>
            </div>
        </div>
    );
}

export default VpnIpParte2;