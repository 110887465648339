import React from 'react';
import BotaoContato from '../../components/BotaoContato';
import styles from '../NossasSolucoes.module.css';
import { columnClasses } from './../../sharedProperties';

function CloudServerParte4() {
    return (
        <div className="container" style={{ margin: "0px", maxWidth: "100%" }} >
            <div className="row" >
                <div className={`${columnClasses.colNormal} ${styles.nossasSolucoes} `}
                    style={{ background: `url("/fundoCloudServer/cloudServer_fundo_parte4_esquerda.png")` }}>
                    <div className={`d-flex flex-column justify-content-center text_align_right ${styles.nossasSolucoes} ${styles.sobreNosTexto}`}>
                        <h1>SUPORTE</h1>
                        <h1>COMPLETO</h1>
                        <p>Nossa equipe técnica especializada oferece suporte completo, incluindo <span>backup e restore</span>, monitoramento de recursos e manutenção local. Economize ainda mais com a AMZ System, eliminando custos com aquisição de hardware e software, consumo de energia e <span>equipe técnica dedicada</span>.</p>
                        <BotaoContato text="OBTER SOLUÇÃO" />
                    </div>
                </div>
                <div className={`${columnClasses.colNormal} ${styles.nossasSolucoes} `}>
                    <img src="/fundoCloudServer/cloudServer_fundo_parte4_direita.png" alt="" className={`${styles.imagem}`} />
                </div>
            </div>
        </div>
    );
}

export default CloudServerParte4;