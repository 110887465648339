import React from 'react';
import styles from './SobreNosParte4.module.css';

function SobreNosParte4() {
    return (
        <div className={styles.sobreNosParte4}>
            <div className="container">
                <div className="row justify-content-center" style={{paddingTop: "30px"}}>
                    <div className={`col-sm d-flex justify-content-center align-items-center`}>
                        <div className={`${styles.roundedShadowDiv}`}>
                            <p style={{ fontSize: "22px", fontWeight: 'bold', color: "#102D49", margin: "3px", textTransform: "uppercase" }}>
                                MISSÃO
                            </p>
                            <p className={`${styles.textoBrancoNossasSolucoes}`}>
                                A satisfação do cliente é o cerne de nossa missão. Oferecemos serviços e soluções inovadoras, alinhadas com as demandas do mercado, para concretizar o potencial de nossos clientes. Acreditamos que nossa parceria vai além da prestação de serviços; é um compromisso contínuo com o sucesso e o crescimento mútuo.
                            </p>
                        </div>
                    </div>
                    <div className={`col-sm d-flex justify-content-center align-items-center`}>
                        <div className={`${styles.roundedShadowDiv}`}>
                            <p style={{ fontSize: "22px", fontWeight: 'bold', color: "#102D49", margin: "3px", textTransform: "uppercase" }}>
                                VISÃO</p>
                            <p className={`${styles.textoBrancoNossasSolucoes}`}>
                                Nossa visão é ser referência incontestável na oferta de serviços e soluções tecnológicas inovadoras e de alta qualidade. Estamos determinados a ser o catalisador da transformação digital, capacitando empresas a prosperarem em um mundo cada vez mais conectado e tecnológico.
                            </p>
                        </div>
                    </div>
                    <div className={`col-sm d-flex justify-content-center align-items-center`}>
                        <div className={`${styles.roundedShadowDiv}`}>
                            <p style={{ fontSize: "22px", fontWeight: 'bold', color: "#102D49", margin: "3px", textTransform: "uppercase" }}>
                                VALORES</p>
                            <p className={`${styles.textoBrancoNossasSolucoes}`}>
                                Nossos valores fundamentais - Inovação, Segurança, Ética, Qualidade, Empatia e Espírito de Equipe - permeiam cada aspecto de nossa cultura empresarial. São esses princípios que sustentam nossa busca incessante pela excelência, assegurando a confiança, disponibilidade e segurança dos sistemas e informações de nossos clientes.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SobreNosParte4;