import React from 'react';
import BotaoContato from '../../components/BotaoContato';
import styles from '../NossasSolucoes.module.css';
import { columnClasses } from './../../sharedProperties';

function ServidoresDedicadosParte4() {
    return (
        <div className="container" style={{ margin: "0px", maxWidth: "100%" }} >
            <div className="row" >
                <div className={`${columnClasses.colNormal} ${styles.nossasSolucoes} `}
                    style={{ background: `url("/fundoServidoresDedicados/servidoresDedicados_fundo_parte4_esquerda.png")` }}>
                    <div className={`d-flex flex-column justify-content-center text_align_right ${styles.nossasSolucoes} ${styles.sobreNosTexto}`}>
                        <h1>Customização</h1>
                        <h1>para Necessidades</h1>
                        <h1>Específicas</h1>
                        <p>
                            Tenha a liberdade de escolher os <span>recursos sob medida</span> para as necessidades específicas do seu negócio. Conte com nossa expertise para configurar seu servidor dedicado de acordo com suas exigências de desempenho, <span>segurança e disponibilidade</span>.
                        </p>
                        <BotaoContato text="CONFIGURAR SEU SERVIDOR DEDICADO" />
                    </div>
                </div>
                <div className={`${columnClasses.colNormal} ${styles.nossasSolucoes} `}>
                    <img src="/fundoServidoresDedicados/servidoresDedicados_fundo_parte4_direita.png" alt="" className={`${styles.imagem}`} />
                </div>
            </div>
        </div>
    );
}

export default ServidoresDedicadosParte4;