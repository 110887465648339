import React from 'react';
import ScrollToTop from '../components/ScrollToTop';
//import React, { useState } from 'react';
//import FlashContext from '../FlashContext';
import Header from '../components/Header';
import Rodape from '../components/Rodape';
import HomeVideo from '../components/HomeVideo';
import HomeNossasSolucoes from '../components/HomeNossasSolucoes';
import HamburguerMenu from '../components/HamburguerMenu';

function Home() {
  //const [shouldFlash, setShouldFlash] = useState(false);
  return <>
    {/*<FlashContext.Provider value={{ shouldFlash, setShouldFlash }}>*/}
      <HamburguerMenu />
      <ScrollToTop />
      <Header />
      <HomeVideo />
    {/*</FlashContext.Provider>*/}
    <HomeNossasSolucoes />

    <Rodape />
  </>;
}

export default Home;