import React from 'react';
import BotaoContato from '../../components/BotaoContato';
import styles from '../NossasSolucoes.module.css';
import { columnClasses } from './../../sharedProperties';

function SobreNosParte2() {
    return (
        <div className="container" style={{ margin: "0px", maxWidth: "100%" }} >
            <div className="row d-flex">
                <div className={`${columnClasses.colNormal}`}>

                    <div className={`d-flex flex-column justify-content-center text_align_right ${styles.nossasSolucoes} ${styles.sobreNosTexto}`}>
                        <p>SOLUÇÕES PARA SUA</p>
                        <h1 >EMPRESA</h1>
                        <p>
                            Reconhecemos a importância vital de nossa oferta de serviços, que constituem a espinha dorsal das operações empresariais, servindo como alicerces para diversas aplicações críticas,
                            incluindo <span> ERP, CRM, Bancos de Dados, Armazenamento de Arquivos e E-mail Corporativo. </span>
                            Nos orgulhamos em ser uma referência em qualidade, proporcionando aos nossos clientes a liberdade para concentrarem seus esforços e recursos no desenvolvimento
                            de seu <span>Core Business.</span>
                        </p>
                        <BotaoContato className="ml-auto" text="CONHEÇA NOSSAS SOLUÇÕES" />
                    </div>
                </div>
                <div className={`${columnClasses.colNormal} ${styles.nossasSolucoes} `}>
                    <img src="/sobreNos/sobreNos_fundo_parte2_direita.png" alt="" className={`${styles.imagem}`} />
                </div>
            </div>
        </div>
    );
}

export default SobreNosParte2;