import React from 'react';
import styles from './BotaoContato.module.css';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

function BotaoContato({ text }) {
    return (
        <Link className={`btn btn-outline ${styles.btn}`} to="/contato">{text}</Link>
    );
}

export default BotaoContato;