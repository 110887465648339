import React from 'react';
import styles from './SobreNosParte1.module.css';

import 'bootstrap/dist/css/bootstrap.min.css';

function SobreNosParte1() {
    return (
        <div className={styles.sobreNosParte1}>
            <h1>
                Sobre nós
            </h1>
            <h2>
                <span>Pioneirismo</span> e <span>Excelência</span> em <br />Tecnologia da Informação e <br />Telecomunicações
            </h2>
            <p>A <span>AMZ SYSTEM</span> emerge como uma vanguarda no cenário de Tecnologia da Informação e Telecomunicações, posicionando-se como uma força impulsionadora para empresas de todos os portes e segmentos. Com uma trajetória marcada pelo compromisso inabalável com a qualidade e inovação, somos líderes no fornecimento de soluções tecnológicas avançadas, moldando o futuro digital de nossos clientes.</p>
        </div>
    );
}

export default SobreNosParte1;