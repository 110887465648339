import React from 'react';
import BotaoContato from '../../components/BotaoContato';
import styles from '../NossasSolucoes.module.css';
import { columnClasses } from './../../sharedProperties';

function SobreNosParte3() {
    return (

        <div className="container" style={{ margin: "0px", maxWidth: "100%" }} >
            <div className="row d-flex" >

                <div className={`${columnClasses.colDesk1Mob2} ${styles.nossasSolucoes}`} >
                    <img src="/sobreNos/sobreNos_fundo_parte3_esquerda.png" alt="" className={`${styles.imagem}`} />
                </div>

                <div className={`${columnClasses.colDesk2Mob1} ${styles.nossasSolucoes}`}>
                    <div className={`d-flex flex-column justify-content-center  ${styles.nossasSolucoes}`} style={{ height: '100%', width: "70%" }}>
                        <p>COMPROMISSO COM</p>
                        <h1>VOCÊ</h1>
                        <p>
                            Nossa fundação é fruto da visão estratégica e da experiência acumulada ao longo de mais de 25 anos de atuação no dinâmico setor de tecnologia. Essa expertise nos permite antecipar as necessidades do mercado e oferecer soluções sob medida que impulsionam o crescimento e a eficiência operacional de nossos clientes.
                        </p>
                        <BotaoContato text="CONHEÇA NOSSAS SOLUÇÕES" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SobreNosParte3;