import React from 'react';
import BotaoContato from '../../components/BotaoContato';
import styles from '../NossasSolucoes.module.css';
import { columnClasses } from './../../sharedProperties';

function VpnIpParte5() {
    return (
        <div className="container" style={{ margin: "0px", maxWidth: "100%" }} >
            <div className="row" >
                <div className={`${columnClasses.colDesk1Mob2} ${styles.nossasSolucoes}`}
                    style={{ textAlign: 'center' }}>
                    <img src="/roboVpnIp.gif" alt="Robô VPN-IP" className={`${styles.gifDoRobo}`} />
                </div>
                <div className={`${columnClasses.colDesk2Mob1} ${styles.nossasSolucoes}`}
                    style={{ background: `url("/ffundoVpnIp/vpnIp_fundo_parte5_direita.png")` }}>
                    <div className={`d-flex flex-column justify-content-center ${styles.nossasSolucoes} ${styles.sobreNosTexto}`}>
                        <h1>sua empresa</h1>
                        <h1>conectada com</h1>
                        <h1>segurança</h1>
                        <p>
                            Proteja seus dados e garanta a segurança de sua empresa agora mesmo. Entre em contato e descubra como nossa solução <span>VPN-IP</span> pode fortalecer sua infraestrutura de TI.<br />O futuro da sua empresa começa agora.
                        </p>
                        <BotaoContato text="PROTEGER SUA EMPRESA AGORA" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VpnIpParte5;