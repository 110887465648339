import React from 'react';
import ScrollToTop from '../components/ScrollToTop';
import Header from '../components/Header';
import Rodape from '../components/Rodape';
import HamburguerMenu from '../components/HamburguerMenu';

import TextoPoliticaDePrivacidade from '../components/politicaDePrivacidade/TextoPoliticaDePrivacidade';

function PoliticaDePrivacidade() {
  return <>
    <HamburguerMenu />
    <ScrollToTop />
    <Header />
    <TextoPoliticaDePrivacidade />
    <Rodape />
  </>;
}

export default PoliticaDePrivacidade;