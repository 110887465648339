import React from 'react';
import BotaoContato from '../../components/BotaoContato';
import styles from '../NossasSolucoes.module.css';
import { columnClasses } from './../../sharedProperties';

function ServidoresDedicadosParte5() {
    return (
        <div className="container" style={{ margin: "0px", maxWidth: "100%" }} >
            <div className="row" >
                <div className={`${columnClasses.colDesk1Mob2} ${styles.nossasSolucoes}`}
                    style={{ textAlign: 'center'}}>
                    <img src="/roboServidoresDedicados.gif" alt="Robô VPN-IP" className={`${styles.gifDoRobo}`} />
                </div>
                <div className={`${columnClasses.colDesk2Mob1} ${styles.nossasSolucoes}`}
                    style={{ background: `url("/fundoCloudServer/cloudServer_fundo_parte5_direita.png")` }}>
                    <div className={`d-flex flex-column justify-content-center ${styles.nossasSolucoes} ${styles.sobreNosTexto}`}>
                        <h1>eLeve sua</h1>
                        <h1>Empresa a um</h1>
                        <h1>novo patamar</h1>
                        <p>
                            Está pronto para levar sua empresa ao próximo nível de desempenho e confiabilidade? Entre em contato com um de nossos especialistas e descubra como nossos Servidores Dedicados podem impulsionar o crescimento e a eficiência do seu negócio. O futuro está esperando por você!
                        </p>
                        <BotaoContato text="CONSULTAR ESPECIALISTA" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ServidoresDedicadosParte5;