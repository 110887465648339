import React from 'react';
import styles from '../NossasSolucoes.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function VpnIpParte1() {
    return (
        <div className={`d-flex align-items-center ${styles.pageStart}`}
            style={{ background: `url("/fundoVpnIp/vpnIp_fundo_parte1.png")` }}>
            
            <div className="text-center w-100">
                <h1>VPN-<span>IP</span></h1>
                <h2><span>AMZ</span> SYSTEM</h2>
                <p>
                    Proteja sua empresa com nossa solução <span>VPN-IP</span><br />
                    <span>Segurança</span> e <span>Conectividade</span> garantidas!
                </p>
            </div>
            
        </div>
    );
}

export default VpnIpParte1;