import React from 'react';
import ScrollToTop from '../components/ScrollToTop';
import Header from '../components/Header';
import Rodape from '../components/Rodape';
import SobreNosParte1 from '../components/sobreNos/SobreNosParte1';
import SobreNosParte2 from '../components/sobreNos/SobreNosParte2';
import SobreNosParte3 from '../components/sobreNos/SobreNosParte3';
import SobreNosParte4 from '../components/sobreNos/SobreNosParte4';
import SobreNosParte5 from '../components/sobreNos/SobreNosParte5';
import HamburguerMenu from '../components/HamburguerMenu';

function SobreNos() {
  return <>
    <HamburguerMenu />
    <ScrollToTop />
    <Header />
    <SobreNosParte1 />
    <SobreNosParte2 />
    <SobreNosParte3 />
    <SobreNosParte4 />
    <SobreNosParte5 />
    <Rodape />
  </>;
}

export default SobreNos;