import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Rodape.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faWhatsapp, /*faFacebook,*/ faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';

function Rodape() {
    return (
        <div className={styles.rodape}>
            <div className="container">
                <div className="row">
                    <div className="col" style={{ marginTop: "15px" }}>
                        <div className={styles.rodapeContato}>
                            <p style={{ fontSize: "20px", fontWeight: 'bold', margin: "3px" }}>Contato</p>

                            <p style={{ fontSize: "14px", margin: "3px" }}>
                                <a href="https://www.google.com.br/maps/place/Av.+Andr%C3%A9+Ara%C3%BAjo,+97+-+Adrian%C3%B3polis,+Manaus+-+AM,+69057-025/@-3.1076671,-60.0086779,18.47z/data=!4m6!3m5!1s0x926c054c1eeb4335:0xc919744d53dad466!8m2!3d-3.106962!4d-60.0095971!16s%2Fg%2F11cncjb_rz?entry=ttu" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faLocationDot} style={{ marginRight: "10px" }} />
                                    Av. André Araújo, 97 – Cj. 216 Adrianópolis  Manaus, Amazonas –  69057-025
                                </a>
                            </p>

                            <p style={{ fontSize: "14px", margin: "3px" }}>

                                <a href="https://api.whatsapp.com/send/?phone=5592993005900&text&type=phone_number&app_absent=0" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faWhatsapp} style={{ marginRight: "10px" }} />(92) 99300-5900
                                </a>
                            </p>
                            <p style={{ fontSize: "14px", margin: "3px" }}>
                                <a href="mailto:contato@amzsystem.com.br" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: "10px" }} />
                                    contato@amzsystem.com.br
                                </a>
                            </p>
                        </div>
                    </div>
                    <div className="col" style={{ marginTop: "15px" }}>
                        <div className={styles.rodapeContato}>
                            <p style={{ fontSize: "20px", fontWeight: 'bold', margin: "3px" }}>Sobre</p>
                            <p style={{ fontSize: "14px", margin: "3px" }}>
                                <Link to="/sobre-nos">Quem Somos</Link>
                            </p>
                            <p style={{ fontSize: "14px", margin: "3px" }}>
                                <Link to="/nossas-solucoes">Nossas Soluções</Link>
                            </p>
                            <p style={{ fontSize: "14px", margin: "3px" }}>
                                <Link to="/politica-de-privacidade">Política de Privacidade</Link>
                            </p>
                            {/*<FontAwesomeIcon icon={faFacebook} style={{ fontSize: "35px" }} />
                            &nbsp;&nbsp;*/}
                            <a style={{ color: 'white', marginRight: "16px" }} href="https://www.linkedin.com/company/amzsystem/" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faLinkedin} style={{ fontSize: "35px", marginTop: "16px" }} />
                            </a>
                            &nbsp;&nbsp;
                            <a style={{ color: 'white', marginRight: "16px", marginTop: "16px" }} href="https://www.instagram.com/amzsystem/" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faInstagram} style={{ fontSize: "35px" }} />
                            </a>
                            &nbsp;&nbsp;
                            <a style={{ color: "white", marginRight: "16px", marginTop: "16px" }} href="https://api.whatsapp.com/send/?phone=5592993005900&text&type=phone_number&app_absent=0" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faWhatsapp} style={{ fontSize: "35px" }} />
                            </a>
                        </div>
                    </div>
                    <div className="col" style={{ marginTop: "15px" }}>
                        <img src="/Logotipo - Sem fundo 3.png" alt="Logotipo - Sem fundo 3" width="300" height="75" style={{ objectFit: 'cover' }} />
                    </div>
                    <div className="row">
                        <hr style={{ width: "100%", borderColor: "white", marginTop: "20px" }} />
                        <p style={{ fontSize: "14px", margin: "3px", textAlign: 'center' }}>
                            AMZ SYSTEM © 2024 | Todos os diretos reservados
                        </p>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Rodape;