import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToAnchor() {
  const { pathname } = useLocation();
  
  useEffect(() => {
    const prevScrollBehavior = document.documentElement.style.scrollBehavior;
    document.documentElement.style.scrollBehavior = 'auto';
    const anchorElement = document.getElementById('myAnchorId');
    if (anchorElement) {
      anchorElement.scrollIntoView();
    }
    document.documentElement.style.scrollBehavior = prevScrollBehavior;
  }, [pathname]);
  return null;
}

export default ScrollToAnchor;