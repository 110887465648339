import React from 'react';
import styles from '../NossasSolucoes.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft, faQuoteRight} from '@fortawesome/free-solid-svg-icons';

function SobreNosParte5() {
    return (
        <div className={`${styles.sobreNosFinal}`}
            style={{ background: `url("/sobreNos/sobreNos_textura.png")` }}>



            <div style={{ width: "80%", borderColor: "#8F9EBA", borderWidth: "4px", opacity: 1, margin: "0 auto" }}>

                <div className="container" >
                    <div className="row d-flex align-items-center">
                        <div className="col flex-grow-1" >
                            <hr style={{ width: "100%", borderColor: "#8F9EBA", borderWidth: "4px", opacity: 1, margin: "0 auto" }} />
                        </div>
                    </div>
                    <div className="row d-flex align-items-center">
                        <div className="col flex-grow-1" style={{ marginTop: "20px", marginBottom: "20px"}}>
                            <p style={{ display: 'inline', lineHeight: '2' }}>
                                <FontAwesomeIcon icon={faQuoteLeft} size="2x" color='#8F9EBA' opacity={'0.7'}/> Na <span>AMZ System</span>, não apenas abraçamos o futuro da tecnologia, mas moldamos ativamente o caminho para
                                que nossos clientes prosperem em um mundo digital em constante evolução. Conecte-se conosco e
                                descubra como a excelência em tecnologia pode impulsionar seu negócio para novas alturas.
                                <FontAwesomeIcon icon={faQuoteRight} size="2x" color='#8F9EBA' opacity={'0.7'}/>
                            </p>
                        </div>
                    </div>
                    <div className="row d-flex align-items-center" style={{ marginBottom: "20px"}}>
                        <div className="col flex-grow-1">
                            <hr style={{ width: "100%", borderColor: "#8F9EBA", borderWidth: "4px", opacity: 1, margin: "0 auto" }} />
                        </div>
                        <div className="col-auto">
                            <img src="/sobreNos/sobreNos_mao.png" alt='' />
                        </div>
                        <div className="col flex-grow-1">
                            <hr style={{ width: "100%", borderColor: "#8F9EBA", borderWidth: "4px", opacity: 1, margin: "0 auto" }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SobreNosParte5;
