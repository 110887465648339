//import React, { useContext } from 'react';
import React from 'react';
//import FlashContext from '../FlashContext';
import styles from './Header.module.css';
import { Link, NavLink } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

function Header() {
    //const { } = useContext(FlashContext);

    return (<>
        <div className={styles.header}>
            <div className="container">
            <div className="row">
            <div className="col-sm" style={{marginBottom: "10px", textAlign: "left" }}>
                <Link to="/" >
                    <img src="/Logotipo - Sem fundo 3.png" alt="Logotipo - Sem fundo 3" width="215" height="34" style={{ objectFit: 'cover' }} />
                </Link>
            </div>

            <div className={`col-sm ${styles.fixed}`}>
                <Link className={"btn btn-outline-light"} style={{ width:"170px", marginBottom: "15px"}} to="/sobre-nos">Sobre Nós</Link>
            </div>

            <div className={`col-sm ${styles.fixed}`}>
                <Link className={`btn btn-outline-light dropdown-toggle}`} style={{ width:"170px", marginBottom: "15px"}} id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Nossas Soluções
                </Link>
                <ul className={`dropdown-menu`} aria-labelledby="navbarDropdownMenuLink" style={{background: 'linear-gradient(180deg, #191D52 0%, #3841B8 100%)'}}>
                    <li><NavLink className={`dropdown-item my-dropdown-item ${styles.my_dropdown_item}`} to="/cloud-server">Armazenamento Em Nuvem</NavLink></li>
                    <li><NavLink className={`dropdown-item my-dropdown-item ${styles.my_dropdown_item}`} to="/servidores-dedicados">Servidores Dedicados</NavLink></li>
                    <li><NavLink className={`dropdown-item my-dropdown-item ${styles.my_dropdown_item}`} to="/vpn-ip">Vpn-Ip</NavLink></li>
                </ul>
            </div>
            <div className={`col-sm ${styles.fixed}`} >
                <NavLink className={"btn btn-outline-light"} style={{width:"170px", marginBottom: "15px"}} to="/contato" >Contato</NavLink>
            </div>
            </div>
            </div>
        </div>
        
        </>
    );
}

export default Header;