import React from 'react';
import ScrollToTop from '../components/ScrollToTop';
import Header from '../components/Header';
import Rodape from '../components/Rodape';
import ContatoParte1 from '../components/contato/ContatoParte1';
import HamburguerMenu from '../components/HamburguerMenu';

function Contato() {
  return <>
    <HamburguerMenu />
    <ScrollToTop />
    <Header />
    <ContatoParte1 />
    <Rodape />
  </>;
}

export default Contato;