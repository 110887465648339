import React from 'react';
import styles from './ContatoParte1.module.css';
//import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp, /*faFacebook,*/ faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

function ContatoParte1() {
    const handleSubmit = (event) => {
        event.preventDefault();

        toastr.options.positionClass = 'toast-top-right';
        toastr.options.preventDuplicates = true;

        const name = document.getElementById('input_nome').value;
        const email = document.getElementById('input_email').value;
        const message = document.getElementById('input_mensagem').value;

        if (!name) {
            toastr.error('Informe seu nome');
            return;
        }

        if (!email) {
            toastr.error('Informe seu e-mail');
            return;
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            toastr.error('Informe um e-mail válido');
            return;
        }

        if (!message) {
            toastr.error('Informe sua mensagem');
            return;
        }

        if (message.length < 10) {
            toastr.error('A mensagem deve ter no mínimo 10 caracteres');
            return;
        }

        fetch('https://amzsystem.com.br/send_mail.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
            body: `name=${encodeURIComponent(name)}&email=${encodeURIComponent(email)}&message=${encodeURIComponent(message)}`,
        })
            .then(response => response.text())
            .then(data => {
                if (data === 'erro') {
                    toastr.error('Ocorreu um erro ao enviar a mensagem. Entre em contato através dos meios abaixo.');
                    return;
                }
                toastr.success('Mensagem enviada com sucesso');
            })
            .catch((error) => {
                toastr.error('Ocorreu um erro ao enviar a mensagem. Entre em contato através dos meios abaixo.');
            });
    }

    return (
        <div className={styles.contatoParte1}>
            <div className="container">
                <div className="row">
                    <div className="col-sm" >
                        <p style={{ fontSize: "48px", color: "#191D52" }}>
                            Contato
                        </p>
                        <p style={{ fontSize: "16px", color: "#191D52" }}>
                            Entre em contato com um de nossos especialistas e descubra como podemos impulsionar sua empresa para o futuro digital.
                        </p>
                        <p style={{ fontSize: "16px", fontWeight: "700", color: "#191D52" }}>
                            Faça parte dessa revolução digital!
                        </p>
                        {/*<FontAwesomeIcon style={{ color: "#191D52", fontSize: "48px" }} icon={faFacebook} />
                        &nbsp;&nbsp;*/}
                        <a style={{ color: "#191D52", fontSize: "48px", marginRight: "16px" }} href="https://www.linkedin.com/company/amzsystem/" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faLinkedin} />
                        </a>
                        &nbsp;&nbsp;
                        <a style={{ color: "#191D52", fontSize: "48px", marginRight: "16px" }} href="https://www.instagram.com/amzsystem/" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                        &nbsp;&nbsp;
                        <a style={{ color: "#191D52", fontSize: "48px" }} href="https://api.whatsapp.com/send/?phone=5592993005900&text&type=phone_number&app_absent=0" target="_blank" rel="noopener noreferrer">
                            <FontAwesomeIcon icon={faWhatsapp} />
                        </a>
                    </div>
                    <div className="col-sm">
                        <img src="/roboContato.gif" alt="Robô Telefonando" style={{ width: '300px' }} />
                    </div>
                    <div className="col-sm">
                        <label htmlFor="input_nome" style={{ marginTop: '15px' }}>Nome</label>
                        <input className="form-control" type="text" id="input_nome" style={{ width: "90%", borderRadius: '10px' }} />

                        <label htmlFor="input_email" style={{ marginTop: '15px' }}>Email</label>
                        <input className="form-control" type="text" id="input_email" style={{ width: "90%", borderRadius: '10px' }} />

                        <label htmlFor="input_mensagem" style={{ marginTop: '15px' }}>Mensagem</label>
                        <textarea className="form-control" id="input_mensagem" style={{ width: "90%", borderRadius: '10px', minHeight: '150px' }} />

                        <div style={{
                            textAlign: "right",
                            paddingTop: "30px",
                            marginBottom: "30px",
                            width: "90%"
                        }}>
                            <button className={`btn btn-outline ${styles.btn}`} onClick={handleSubmit}>ENVIAR</button>
                        </div>

                    </div>

                </div>

            </div>

        </div>
    );
}

export default ContatoParte1;