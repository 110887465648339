import React from 'react';
import styles from '../NossasSolucoes.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function CloudServerParte1() {
    return (
        <div className={`d-flex align-items-center ${styles.pageStart}`}
            style={{ background: `url("/fundoCloudServer/cloudServer_fundo_parte1.png")` }}>
            
            <div className="text-center w-100">
                <h1>CLOUD <span>SERVER</span></h1>
                <h2><span>AMZ</span> SYSTEM</h2>
                <p>
                    A solução perfeita para <span>impulsionar</span> a <span>infraestrutura</span> de tecnologia da
                    <br/>informação da sua empresa!
                </p>
            </div>
            
        </div>
    );
}

export default CloudServerParte1;