// src/components/ScrollButton.js
import React from 'react';
import FlashContext from '../FlashContext';

class ScrollButton extends React.Component {
  static contextType = FlashContext;

  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    this.context.setShouldFlash(true);
    setTimeout(() => this.context.setShouldFlash(false), 2000);
  }

  scrollToAnchor = () => {
    const element = document.getElementById('myAnchorId');
    if (element) {
      element.scrollIntoView({
        behavior: "smooth"
      });
    }
  }

  render() {
    return <button className="btn btn-outline-light "
                style={{ margin: "3px", textTransform: "uppercase" }}
                onClick={this.scrollToAnchor}
                >
        Conheça nossas Soluções
        </button>;
  }
}

export default ScrollButton;