import React from 'react';
import styles from './HomeVideo.module.css';
//import { Link, NavLink } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import ScrollButton from './ScrollButton';

function HomeVideo() {
    return (
        <div className={`d-flex align-items-center ${styles.homeVideo}`}>
            <div className="text-center w-100">
                <p className="mb-0" style={{ fontWeight: 'bold', textTransform: "uppercase" }}>
                    Hiperconvergência<br />
                    Alta Disponibilidade<br />
                    Baixa Latência<br />
                    Tráfego Ilimitado<br /><br />
                </p>
                <ScrollButton />
            </div>
        </div>
    );
}

export default HomeVideo;