import React from 'react';
import styles from './HomeNossasSolucoes.module.css';
import { NavLink } from 'react-router-dom';

function HomeNossasSolucoes() {
    return (
        <div className={styles.homeNossasSolucoes} id="myAnchorId">
            <div className="container">


                <div className={`row `} >
                    <div className={`col-6 ${styles.divTitulo}`}>
                        <p style={{ color: "#191D52", margin: "3px", textTransform: "uppercase", letterSpacing: "4px", marginBottom: "0px" }}>
                            SOLUÇÕES PENSADAS</p>
                        <p style={{ color: "#191D52", margin: "3px", textTransform: "uppercase", letterSpacing: "4px", marginBottom: "40px", textDecoration: "underline" }}>
                            PARA O SEU <b>NEGÓCIO</b></p>
                    </div>
                    <div className={`col-6`}>
                        <div></div>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className={`col-sm ${styles.roundedShadowDiv}`}>
                        <h1>
                            CLOUD SERVER
                        </h1>
                        <p>
                            A solução de Cloud Server ideal<br />
                            para a sua empresa.
                        </p>
                        <img src="cloud_arrow.png" alt="Cloud Server" /><br />
                        <NavLink className={"btn btn-outline-light"} to="/cloud-server" style={{ margin: '25px' }}>
                            Conheça Agora
                        </NavLink>
                    </div>
                    <div className={`col-sm ${styles.roundedShadowDiv}`}>
                        <h1>
                            SERVIDORES<br />DEDICADOS
                        </h1>
                        <p>
                            Alto poder e performance para<br />
                            a sua empresa.
                        </p>
                        <img src="server.png" alt="Server" /><br />
                        <NavLink className={"btn btn-outline-light"} to="/servidores-dedicados" style={{ margin: '25px' }}>
                            Conheça Agora
                        </NavLink>
                    </div>
                    <div className={`col-sm ${styles.roundedShadowDiv}`}>
                        <h1>
                            VPN-IP
                        </h1>
                        <p>
                            A solução inteligente para<br />
                            interligação de matriz e filiais via<br />
                            Internet.
                        </p>
                        <img src="shield.png" alt="Server" /><br />
                        <NavLink className={"btn btn-outline-light"} to="/vpn-ip" style={{ margin: '25px' }}>
                            Conheça Agora
                        </NavLink>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomeNossasSolucoes;