import React from 'react';
import { slide as Menu } from 'react-burger-menu'
import { NavLink } from 'react-router-dom';
import './HamburguerMenu.css'; // Import the CSS file

class HamburguerMenu extends React.Component {
  /*showSettings (event) {
    event.preventDefault();
  }*/

  render () {
    // NOTE: You also need to provide styles, see https://github.com/negomi/react-burger-menu#styling
    return (
      <Menu right>
        {/*<a id="home" className="menu-item" href="/">Home</a>
        <a id="about" className="menu-item" href="/">About</a>
        <a id="contact" className="menu-item" href="/">Contact</a>
    <a onClick={ this.showSettings } className="menu-item--small" href="/">Settings</a>*/}

        <NavLink className={"btn btn-outline-light"} style={{ width:"170px", marginBottom: "15px"}} to="/">Home</NavLink>

        <NavLink className={"btn btn-outline-light"} style={{ width:"170px", marginBottom: "15px"}} to="/sobre-nos">Sobre Nós</NavLink>

        <NavLink className={"btn btn-outline-light"} style={{width:"170px", marginBottom: "15px"}} to="/cloud-server">Armazenamento Em Nuvem</NavLink>
        <NavLink className={"btn btn-outline-light"} style={{width:"170px", marginBottom: "15px"}} to="/servidores-dedicados">Servidores Dedicados</NavLink>
        <NavLink className={"btn btn-outline-light"} style={{width:"170px", marginBottom: "15px"}} to="/vpn-ip">Vpn-Ip</NavLink>

        <NavLink className={"btn btn-outline-light"} style={{width:"170px", marginBottom: "15px"}} to="/contato" >Contato</NavLink>
      </Menu>
    );
  }
}

export default HamburguerMenu;