//import React from 'react';
import React/*, { useState }*/ from 'react';
//import FlashContext from './FlashContext';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
//import { HashRouter as Router, Routes, Route } from 'react-router-dom';

import Home from './pages/Home';
import HomeNS from './pages/HomeNS';
import SobreNos from './pages/SobreNos';
import CloudServer from './pages/CloudServer';
import ServidoresDedicados from './pages/ServidoresDedicados';
import VpnIp from './pages/VpnIp';
import Contato from './pages/Contato';
import PoliticaDePrivacidade from './pages/PoliticaDePrivacidade';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

function App() {
  //const [shouldFlash, setShouldFlash] = useState(false)
  /*<FlashContext.Provider value={{ shouldFlash, setShouldFlash }}>*/
  /*</FlashContext.Provider>*/
  return (
    
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/nossas-solucoes" element={<HomeNS />} />
          <Route path="/sobre-nos" element={<SobreNos />} />
          <Route path="/cloud-server" element={<CloudServer />} />
          <Route path="/servidores-dedicados" element={<ServidoresDedicados />} />
          <Route path="/vpn-ip" element={<VpnIp />} />
          <Route path="/contato" element={<Contato />} />
          <Route path="/politica-de-privacidade" element={<PoliticaDePrivacidade />} />
        </Routes>
      </div>
    </Router>

  );
}
      

export default App;