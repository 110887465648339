import React from 'react';
import ScrollToTop from '../components/ScrollToTop';
import Header from '../components/Header';
import Rodape from '../components/Rodape';
import ServidoresDedicadosParte1 from '../components/servidoresDedicados/ServidoresDedicadosParte1';
import ServidoresDedicadosParte2 from '../components/servidoresDedicados/ServidoresDedicadosParte2';
import ServidoresDedicadosParte3 from '../components/servidoresDedicados/ServidoresDedicadosParte3';
import ServidoresDedicadosParte4 from '../components/servidoresDedicados/ServidoresDedicadosParte4';
import ServidoresDedicadosParte5 from '../components/servidoresDedicados/ServidoresDedicadosParte5';
import HamburguerMenu from '../components/HamburguerMenu';

function ServidoresDedicados() {
  return <>
    <HamburguerMenu />
    <ScrollToTop />
    <Header />
    <ServidoresDedicadosParte1 />
    <ServidoresDedicadosParte2 />
    <ServidoresDedicadosParte3 />
    <ServidoresDedicadosParte4 />
    <ServidoresDedicadosParte5 />
    <Rodape />
  </>;
}

export default ServidoresDedicados;