import React from 'react';
import ScrollToAnchor from '../components/ScrollToAnchor';
//import React, { useState } from 'react';
//import FlashContext from '../FlashContext';
import Header from '../components/Header';
import Rodape from '../components/Rodape';
import HomeVideo from '../components/HomeVideo';
import HomeNossasSolucoes from '../components/HomeNossasSolucoes';
import HamburguerMenu from '../components/HamburguerMenu';

function HomeNS() {
    return <>
        <HamburguerMenu />
        <ScrollToAnchor />
        <Header />
        <HomeVideo />
        <HomeNossasSolucoes />
        <Rodape />
    </>;
}

export default HomeNS;