import React from 'react';
import ScrollToTop from '../components/ScrollToTop';
import Header from '../components/Header';
import Rodape from '../components/Rodape';
import CloudServerParte1 from '../components/cloudServer/CloudServerParte1';
import CloudServerParte2 from '../components/cloudServer/CloudServerParte2';
import CloudServerParte3 from '../components/cloudServer/CloudServerParte3';
import CloudServerParte4 from '../components/cloudServer/CloudServerParte4';
import CloudServerParte5 from '../components/cloudServer/CloudServerParte5';
import HamburguerMenu from '../components/HamburguerMenu';

function CloudServer() {
  return <>
    <HamburguerMenu />
    <ScrollToTop />
    <Header />
    <CloudServerParte1 />
    <CloudServerParte2 />
    <CloudServerParte3 />
    <CloudServerParte4 />
    <CloudServerParte5 />
    <Rodape />
  </>;
}

export default CloudServer;