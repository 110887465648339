import React from 'react';
import BotaoContato from '../../components/BotaoContato';
import styles from '../NossasSolucoes.module.css';
import { columnClasses } from './../../sharedProperties';

function CloudServerParte2() {
    return (
        <div className="container" style={{ margin: "0px", maxWidth: "100%" }} >
            <div className="row" >
                <div className={`${columnClasses.colNormal}`}
                    style={{ background: `url("/fundoCloudServer/cloudServer_fundo_parte2_esquerda.png")` }}>

                    <div className={`d-flex flex-column justify-content-center text_align_right ${styles.nossasSolucoes} ${styles.sobreNosTexto}`}>
                        <h1>LIBERDADE E</h1><h1> FLEXIBILIDADE</h1>
                        <p>
                            Experimente a <span>liberdade</span> e <span>flexibilidade</span> do Cloud Server da AMZ SYSTEM. Nossos servidores são construídos sob medida para atender às necessidades específicas da sua empresa,
                            oferecendo <span>alta disponibilidade, tráfego ilimitado </span> e <span>conexão IP dedicada.</span>
                        </p>
                        <BotaoContato text="OBTER SOLUÇÃO" />
                    </div>
                </div>

                <div className={`${columnClasses.colNormal} ${styles.nossasSolucoes} `}>
                    <img src="/fundoCloudServer/cloudServer_fundo_parte2_direita.png" alt="" className={`${styles.imagem}`} />
                </div>
            </div>
        </div>
    );
}

export default CloudServerParte2;