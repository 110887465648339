import React from 'react';
import styles from '../NossasSolucoes.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function ServidoresDedicadosParte1() {
    return (
        <div className={`d-flex align-items-center ${styles.pageStart}`}
            style={{ background: `url("/fundoServidoresDedicados/servidoresDedicados_fundo_parte1.png")` }}>
            
            <div className="text-center w-100">
                <h1>SERVIDORES <span>DEDICADOS</span></h1>
                <h2><span>AMZ</span> SYSTEM</h2>
                <p>
                    <span>Potência</span> e <span>Confiabilidade</span> com nossos servidores dedicados<br />
                    <span>Desempenho Superior</span> para sua empresa!
                </p>
            </div>
            
        </div>
    );
}

export default ServidoresDedicadosParte1;