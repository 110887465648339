import React from 'react';
import BotaoContato from '../../components/BotaoContato';
import styles from '../NossasSolucoes.module.css';
import { columnClasses } from './../../sharedProperties';

function VpnIpParte3() {
    return (
        <div className="container" style={{ margin: "0px", maxWidth: "100%" }} >
            <div className="row" >
                <div className={`${columnClasses.colDesk1Mob2} ${styles.nossasSolucoes}`} >
                    <img src="/fundoVpnIp/vpnIp_fundo_parte3_esquerda.png" alt="" className={`${styles.imagem}`} />
                </div>
                <div className={`${columnClasses.colDesk2Mob1} ${styles.nossasSolucoes}`}
                    style={{ background: `url("/fundoVpnIp/vpnIp_fundo_parte3_direita.png")` }}>
                    <div className={`d-flex flex-column justify-content-center ${styles.nossasSolucoes} ${styles.sobreNosTexto}`}>
                        <h1>sua empresa</h1>
                        <h1>PROTEGIDa</h1>
                        <p>
                            A VPN-IP é totalmente gerenciável, criando uma conexão segura entre a rede do escritório e os recursos da AMZ SYSTEM. Utilizando túneis <span>IP Security (IPSec)</span>, interligamos os <span>datacenters</span> e filiais de sua empresa a aplicações e serviços baseados na nuvem, garantindo a proteção da confidencialidade dos dados. 
                        </p>
                        <BotaoContato text="OBTER SOLUÇÃO" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VpnIpParte3;